import React from 'react';
import { Badge, Card, Copy, Icons, Image, Title } from '#components/atoms';
import { useDeviceDetector } from '../../hooks';

type FeatureProps = {
  title: string;
  description: string;
  platforms: {
    title: string;
    icon: string;
    fill: string;
    classname: string;
  }[];
};

export const ReportsFeatures = ({
  data,
  copy,
  title,
}: {
  data: FeatureProps[];
  copy: string;
  title: string;
}) => {
  const { DesktopView } = useDeviceDetector();

  return (
    <>
      <div className="lg:hidden">
        <Card className="flex flex-col !px-5 !py-16">
          <Copy className="!text-base !text-blue-400 !font-bold md:text-center">
            {copy}
          </Copy>
          <Title className="text-3xl lg:text-5xl lg:text-center !font-bold mb-8 md:text-center">
            {title}
          </Title>
          {data.map((item, index) => (
            <div
              className="border border-blue-100 md:border-t-[6px]  shadow-sm rounded-sm justify-center items-start py-4 px-8 mb-5"
              key={index}
            >
              <Title className="!text-xl !font-bold">{item.title}</Title>
              <Copy className="!text-base !font-regular mb-2.5">
                {item.description}
              </Copy>
              {item.platforms.map((platform: any) => (
                <Badge
                  key={index}
                  className={`p-5 !text-sm !py-2 ${platform.classname} mr-2.5
                !rounded-full w-fit`}
                >
                  <Icons
                    type={platform.icon}
                    fill={platform.fill}
                    className="mr-2 size-4"
                  />
                  <p>{platform.title}</p>
                </Badge>
              ))}
            </div>
          ))}
          <Image
            src={require('#assets/products/feat-reports-mobile.png')}
            alt="reports"
            width={1000}
            height={1000}
          />
        </Card>
      </div>
      <DesktopView>
        <Copy className="!text-base !text-blue-400 !font-bold lg:text-center lg:!text-xl mb-1 2xl:!text-2xl">
          Las mejores funcionalidades
        </Copy>
        <Title className="text-3xl lg:text-5xl lg:text-center !font-bold mb-7 2xl:text-6xl">
          Lo que necesitas para tu consulta
        </Title>
        <div className="grid grid-cols-12">
          <Image
            src={require('#assets/products/feat-reports-desktop.png')}
            alt="reports"
            width={600}
            height={1000}
            className="col-span-6 col-start-1"
          />
          <div className="col-span-6 mr-24">
            {data.map((item: any, index: number) => (
              <Card
                className="flex flex-col !py-4 !px-8 mb-5 shadow-md border-s-8 border-s-blue-100"
                key={index}
              >
                <Title>{item.title}</Title>
                <Copy className="!text-slate-600 mb-3">{item.description}</Copy>
                <div>
                  {item.platforms.map((platform: any) => (
                    <Badge
                      key={index}
                      className={`p-5 !py-2 ${platform.classname} mr-2.5
             !rounded-full w-fit`}
                    >
                      <Icons
                        type={platform.icon}
                        fill={platform.fill}
                        className="mr-2 size-4"
                      />
                      <p>{platform.title}</p>
                    </Badge>
                  ))}
                </div>
              </Card>
            ))}
          </div>
        </div>
      </DesktopView>
    </>
  );
};
