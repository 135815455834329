import React from 'react';
import { Layout } from '#hocs/layout';
import {
  Banners,
  Doubts,
  OtherSections,
  ProductHighlight,
  TypesOf,
} from '#components/organisms';

const AdministrationView = () => {
  const SECTION = 'administration';

  return (
    <Layout section={SECTION} fill="#FFFF">
      <ProductHighlight section={SECTION} />
      <TypesOf
        header="Bancos, tarjetas y proveedores"
        title=" Configuración de cuentas"
        section={SECTION}
        description="Sabemos que cada clínica tiene sus particularidades. Por eso te damos la
        flexibilidad de configurar tus tarjetas y cuentas bancarias de manera
        personalizada. Así, podrás manejar tus finanzas como a ti te convenga,
        con la seguridad de que todo está bien y listo para operar."
      />
      <OtherSections section={SECTION} />
      <Doubts selectFilter={SECTION} />
      <Banners section={SECTION} />
    </Layout>
  );
};

export default AdministrationView;
