import React from 'react';
import {
  TypesOf,
  OtherSections,
  Remembers,
} from '#components/organisms';
import { Layout } from '#hocs/layout';

const ScheduleView = () => {
  return (
    <Layout section="schedule">
      <TypesOf
        section="schedule"
        title="La agenda que se adapta a lo que necesitas"
      />
      <Remembers section="schedule" title="Simplifica tu día a día" />
      <OtherSections section="general" />
    </Layout>
  );
};

export default ScheduleView;
