import React from 'react';
import {
  Features,
  Remembers,
  ProductHighlight,
  ExperienceImprove,
  Doubts,
  Role,
  FeaturedItems,
  Banners,
} from '#components/organisms';
import { items } from '#mocks/auditory';
import { Layout } from '#hocs/layout';

const AuditoryView = () => {
  const SECTION = 'auditory';

  return (
    <Layout section={SECTION} fill="white">
      <ProductHighlight section={SECTION} />
      <Features
        header="Beneficios"
        title="Descubre todo lo que puedes lograr"
        section={SECTION}
        classnames='text-violet-400'
      />
      <Remembers
        header="Contacto con obras sociales"
        title="Simplificamos la interacción con obras sociales"
        section="auditory"
        classnames="text-violet-400"
      />
      <Banners section="auditory_not_image" />
      <Role
        header="Nuestros usuarios"
        title="Este software sin duda es para ti"
        data={items}
        classnames="!text-violet-400"
      />
      <ExperienceImprove
        section={SECTION}
        header="Capacitaciones"
        title="Te acompañaremos paso a paso"
        classnames="!text-violet-400"
      />
      <FeaturedItems
        section={SECTION}
        classnames="text-violet-400"
        background="bg-[linear-gradient(180deg,_rgba(246,244,255,0)_0%,_#F6F4FF_67%,_rgba(246,244,255,0)_100%)] py-10"
      />
      <Doubts selectFilter={SECTION} />
      <Banners section={SECTION} />
    </Layout>
  );
};
export default AuditoryView;
