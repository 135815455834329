import React from 'react';
import classNames from 'classnames';
import { Button, Copy, Title, Card, Icons } from '#components/atoms';
import { CardContainerT } from '#interfaces/components';

export const CardContainer: React.FC<CardContainerT> = ({
  children,
  buttons,
  title,
  header,
  description,
  classname,
}) => {
  return (
    <Card className={classNames('flex flex-col lg:items-center', classname)}>
      {header && (
        <Copy
          className={classNames(
            'mb-1 !text-base !font-bold lg:!text-xl 2xl:!text-2xl',
            header.color || '!text-blue-400',
          )}
        >
          {header.text}
        </Copy>
      )}
      <Title className="mb-4 text-3xl lg:text-5xl lg:text-center 2xl:text-6xl">
        {title}
      </Title>
      <Copy className="mb-8 !text-xl lg:mx-32 lg:text-center 2xl:!text-2xl">
        {description}
      </Copy>
      {children}
      {buttons?.map(
        (
          b: {
            text: string;
            modifier: string | null;
            action: (section?: string) => void;
            icon?: string;
            fill?: string;
          },
          index: number,
        ) => (
          <Button
            className={classNames('lg:hidden md:h-14 md:!text-base', {
              'mb-5': buttons.length > 0 && index !== buttons.length - 1,
            })}
            modifier={b.modifier}
            onClick={b.action}
            key={index}
          >
            {b.text}
            {b.icon && (
              <Icons type={b.icon} fill={b.fill} className="ml-3 size-6" />
            )}
          </Button>
        ),
      )}
    </Card>
  );
};
