import React from 'react';
import classNames from 'classnames';
import { CardContainer, CollapsibleContainer } from '#components/molecules';
import {
  Image,
  Badge,
  Icons,
  Card,
  Copy,
  Button,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '#components/atoms';

export const Product: React.FC<{
  data: {
    classnames?: string;
    title: string;
    header: { text: string; color?: string };
    description: string;
    isNotCollapsed: boolean;
    isTabInDesktop: boolean;
    containsImage: boolean;
    imgDesktop: string | null;
    childrenData: {
      description_desktop: string | null;
      extend_description_desktop: string | null;
      icon: string | null;
      title: string | null;
      subtitle: string | null;
      classname: string | null;
      fill: string | null;
      img: string | null;
      background: string | null;
      badget?: {
        title: string;
        classname: string;
      };
    }[];
    buttons:
      | {
          text: string;
          modifier: string | null;
          action: (section?: string) => void;
          icon?: string;
          fill?: string;
        }[]
      | null;
    openToggle: boolean;
  };
}> = ({ data }) => {
  const [openIndex, setOpenIndex] = React.useState(0);
  const [selectedImage, setSelectedImage] = React.useState(
    data.childrenData[0].img || 'schedule-data.png',
  );
  const handleToggle = (index: any, element: any) => {
    setSelectedImage(element);
    setOpenIndex((prevIndex) => (prevIndex === index ? 0 : index));
  };

  return (
    <div className={classNames('lg:px-24', data.classnames)}>
      <CardContainer
        buttons={data.buttons}
        title={data.title}
        header={data.header}
        description={data.description}
        classname="!py-10"
      >
        <div className="lg:hidden">
          {data.childrenData.map((c: any, index: number) => (
            <div key={index} className="flex flex-col">
              {data.isNotCollapsed ? (
                <Badge
                  className={`!p-3 mb-5
                !rounded-full w-fit ${c.classname}`}
                >
                  <Icons type={c.icon} fill={c.fill} className="mr-3 size-6" />
                  <p className="uppercase text-base w-fit">{c.title}</p>
                </Badge>
              ) : (
                <>
                  <CollapsibleContainer
                    title={c.title}
                    description={c.subtitle}
                    isOpen={
                      data.openToggle ? data.openToggle : openIndex === index
                    }
                    onToggle={() => handleToggle(index, c.img)}
                  />
                  <span className="mt-2 mb-2 h-0 w-full border-t border-dotted border-gray-300" />
                </>
              )}
            </div>
          ))}
          {data.containsImage && (
            <Image
              src={require(`#assets/products/${selectedImage}`)}
              width={400}
              height={300}
              alt="product-image"
              className="w-full my-7"
            />
          )}
        </div>
      </CardContainer>
      {data.isTabInDesktop ? (
        <div className="mt-5 w-full hidden lg:block">
          <Tabs
            defaultValue={data.childrenData[0].title || ''}
            className="w-full items-center flex flex-col"
          >
            <TabsList>
              {data.childrenData.map((c: any, index: number) => (
                <TabsTrigger
                  key={index}
                  value={c.title}
                  className="2xl:!text-xl"
                >
                  {c.title}
                </TabsTrigger>
              ))}
            </TabsList>
            {data.childrenData.map((c: any, index: number) => (
              <TabsContent key={index} value={c.title}>
                <div className="grid grid-cols-12 w-full mt-10 pb-10">
                  <div className="col-span-5 flex flex-col">
                    <div>
                      <Copy className="!text-xl !font-bold mb-5 2xl:!text-2xl">
                        {c.title}
                      </Copy>
                      <Copy className="!text-xl lg:hidden">{c.subtitle}</Copy>
                      <Copy className="!text-xl hidden lg:block mb-10 2xl:!text-2xl">
                        {c.description_desktop}
                      </Copy>
                      <Copy
                        className={classNames(
                          '!text-xl hidden lg:block 2xl:!text-2xl',
                          c.badget ? 'mb-0' : 'mb-20',
                        )}
                      >
                        {c.extend_description_desktop}
                      </Copy>
                      {c.badget && (
                        <Badge
                          className={classNames(
                            'my-8 !text-sm',
                            c.badget.classname,
                          )}
                        >
                          {c.badget.title}
                        </Badge>
                      )}
                    </div>
                    <div className="flex gap-5 w-full mb-12">
                      {data.buttons?.map(
                        (
                          b: {
                            text: string;
                            modifier: string | null;
                            action: (section?: string) => void;
                            icon?: string;
                            fill?: string;
                          },
                          index: number,
                        ) => (
                          <Button
                            key={index}
                            className="mt-5 w-full"
                            modifier={b.modifier}
                            onClick={() =>
                              b.action(
                                c.title === 'Agenda'
                                  ? 'schedule'
                                  : c.title === 'Pacientes'
                                  ? 'patients'
                                  : 'statistics',
                              )
                            }
                          >
                            {b.text}
                            {b.icon && (
                              <Icons
                                type={b.icon}
                                fill={b.fill}
                                className="ml-3 size-6"
                              />
                            )}
                          </Button>
                        ),
                      )}
                    </div>
                  </div>
                  <div className="col-span-7 ml-4 w-full">
                    {data.containsImage && (
                      <Image
                        src={require(`#assets/products/${c.img}`)}
                        width={400}
                        height={300}
                        alt="product-image"
                        className="w-auto h-auto"
                      />
                    )}
                  </div>
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      ) : (
        <Card className="lg:grid grid-cols-12 hidden p-10">
          <div
            className={classNames(
              data.isNotCollapsed
                ? 'col-span-12 flex gap-5'
                : 'col-span-5 flex flex-col justify-between',
            )}
          >
            {data.childrenData.map((c: any, index: number) => (
              <div
                className={classNames(
                  data.isNotCollapsed && 'w-full shadow-md rounded-md',
                )}
                key={index}
              >
                {data.isNotCollapsed ? (
                  <>
                    <Card className="w-full relative !p-0">
                      <div
                        className={classNames(
                          'w-full h-8 rounded-t-sm',
                          c.background,
                        )}
                      />
                      <Image
                        src={require(`#assets/icons/${c.img}`)}
                        width={400}
                        height={300}
                        alt="product-image"
                        className="absolute size-14 left-6 top-3 2xl:size-16"
                      />
                    </Card>
                    <div className="mt-5 p-6">
                      <Copy className="!text-base text-slate-900 mb-4 uppercase 2xl:!text-2xl">
                        {c.title}
                      </Copy>
                      <Copy className="2xl:!text-xl">{c.subtitle}</Copy>
                    </div>
                  </>
                ) : (
                  <Card className="flex flex-col mb-7 shadow-md border-s-8 border-s-blue-400">
                    <Copy className="!text-xl text-slate-900 2xl:!text-2xl">
                      {c.title}
                    </Copy>
                    <Copy className="!text-base 2xl:!text-xl !text-slate-500">
                      {c.subtitle}
                    </Copy>
                  </Card>
                )}
              </div>
            ))}
            <div
              className={classNames(
                'flex gap-5 w-full mb-12',
                data.isNotCollapsed && 'hidden',
              )}
            >
              {data.buttons?.map(
                (
                  b: {
                    text: string;
                    modifier: string | null;
                    action: () => void;
                  },
                  index: number,
                ) => (
                  <Button
                    key={index}
                    className="mt-16 w-full 2xl:!text-xl 2xl:h-14"
                    modifier={b.modifier}
                    onClick={b.action}
                  >
                    {b.text}
                  </Button>
                ),
              )}
            </div>
          </div>
          <div className="col-span-7 w-full">
            {data.containsImage && !data.isNotCollapsed && (
              <Image
                src={require(`#assets/products/${data.imgDesktop}`)}
                width={400}
                height={300}
                alt="product-image"
                className="w-full h-auto lg:-translate-y-10"
              />
            )}
          </div>
        </Card>
      )}
    </div>
  );
};
