import React from 'react';
import { OtherSections, Remembers, ReportsFeatures} from '#components/organisms';
import { features } from '#mocks/liquidations';
import { Layout } from '#hocs/layout';

const ReportsView = () => {
  return (
    <Layout section="reports">
      <ReportsFeatures
        data={features}
        copy="Las mejores funcionalidades"
        title="Lo que necesitas para tu consulta"
      />
      <Remembers section="reports" />
      <OtherSections section="general"/>
    </Layout>
  );
};
export default ReportsView;
