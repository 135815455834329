import React from 'react';
import classNames from 'classnames';
import { HiCheckCircle } from 'react-icons/hi2';
import { Button, Copy, Image, Title, Icons } from '#components/atoms';
import { checkList } from '#mocks/statistics';
import { useDeviceDetector } from '../../hooks/device-detector';
export const ExperienceImprove = ({
  section,
  header,
  title,
  description,
  classnames = '!text-blue-400',
}: {
  section: string;
  title?: string;
  header?: string;
  description?: string;
  classnames?: string;
}) => {
  const data = checkList[section];
  const { MobileView, TabletView, DesktopView } = useDeviceDetector();

  return (
    <div
      id="experienceImprove"
      className={classNames(
        'mx-5 lg:mx-24 md:pt-2 md:pb-7 lg:py-8 md:flex md:flex-col overflow-hidden',
        data.classnames,
      )}
    >
      {header && (
        <Copy
          className={classNames(
            '!text-base lg:!text-xl !font-bold mb-2 md:mb-5 lg:text-center 2xl:!text-2xl',
            classnames,
          )}
        >
          {header}
        </Copy>
      )}
      {title && (
        <Title className="!text-3xl mb-8 lg:mb-12 lg:text-center lg:!text-5xl 2xl:!text-6xl 2xl:mb-12">
          {title}
        </Title>
      )}
      <div className="lg:grid lg:grid-cols-12">
        <div className="my-10 lg:col-span-6">
          {description && (
            <Copy className="!text-base mb-12 lg:text-start lg:!text-xl lg:mt-16">
              {description}
            </Copy>
          )}
          {data?.items.map((item, index) => (
            <div className="flex items-center gap-x-4 mx-2 my-5" key={index}>
              <HiCheckCircle
                className={classNames('text-blue-500 size-8', item.color)}
              />
              <Copy className="!text-xl 2xl:!text-2xl w-full">{item.text}</Copy>
            </div>
          ))}
          <div className="w-full lg:flex lg:gap-x-6 lg:mt-10">
            {data.buttons && data.buttons.primary && (
              <Button
                onClick={() =>
                  window.open(
                    `${data.buttons?.primary?.url}`,
                    `${data.buttons?.primary?.target}`,
                  )
                }
                className={classNames(
                  'w-full mb-4',
                  data.buttons.primary?.style,
                )}
              >
                {data.buttons.primary.text}
              </Button>
            )}
            {data.buttons && data.buttons.secondary && (
              <Button
                className={classNames('w-full', data.buttons.secondary?.style)}
                onClick={() =>
                  window.open(
                    `${data.buttons?.secondary?.url}`,
                    `${data.buttons?.secondary?.target}`,
                  )
                }
              >
                {data.buttons.secondary.text}
                {data.buttons.secondary.icon && (
                  <Icons type={data.buttons.secondary.icon.type} fill={data.buttons.secondary.icon.fill} className="size-5 ml-2" />
                )}
              </Button>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center lg:col-span-5 lg:col-start-8">
          {data.imgMobile && (
            <>
              <MobileView>
                <Image
                  src={require(`#assets/products/${data.imgMobile}`)}
                  width={400}
                  height={300}
                  alt="product-image"
                />
              </MobileView>
              <TabletView>
                <Image
                  src={require(`#assets/products/${data.imgMobile}`)}
                  width={400}
                  height={300}
                  alt="product-image"
                />
              </TabletView>
              <DesktopView>
                <Image
                  src={require(`#assets/products/${data.imgDesktop}`)}
                  width={400}
                  height={300}
                  alt="product-image"
                />
              </DesktopView>
            </>
          )}
          {!data.imgMobile && (
            <Image
              src={require(`#assets/products/${data.imgDesktop}`)}
              width={400}
              height={300}
              alt="product-image"
            />
          )}
        </div>
      </div>
    </div>
  );
};
