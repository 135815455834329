import React from 'react';
import {
  ProductHighlight,
  Remembers,
  Stories,
  Odontogram,
  OtherSections,
  FeaturedItems,
  Testimonial,
  Recipes,
  Features
} from '#components/organisms';
import { Layout } from '#hocs/layout';

const PatientsView = () => {
  const section = 'patients';

  return (
    <Layout section={section}>
      <ProductHighlight section={section} />
      <FeaturedItems section={section} />
      <Odontogram />
      <Remembers
        header="Presupuestos y planes de tratamiento"
        title="Presupuestos dentales personalizados"
        section="patients_budgets"
      />
      <Features 
       header="Historia clínica, pagos, turnos y galería"
       title="Pacientes, con todo lo que necesitas en un solo lugar"
       section={section}
       classnames='!text-blue-400'
      />
      <Recipes />
      <Remembers
        header="Laboratorio"
        title="Laboratorios de confianza, resultados excepcionales"
        section="patients_laboratories"
      />
      <OtherSections section="general"/>
      <Stories
        title="Historias como la tuya"
        description="Miles de profesionales como vos eligen y seguen eligiendo a Bilog, conoce sus experiencias."
      />
      <div className="lg:hidden">
        <Testimonial />
      </div>
    </Layout>
  );
};
export default PatientsView;
