import React from 'react';
import { Layout } from '#hocs/layout';
import {
  Remembers,
  OtherSections,
  ProductHighlight,
  Stories,
  Testimonial,
  Role,
} from '#components/organisms';
import { items } from '#mocks/shield';

const ShieldView = () => {
  const section = 'shield';

  return (
    <Layout section={section}>
      <ProductHighlight section={section} />
      <Remembers
        header="Ventajas de la Seguridad en Bilog"
        title="Protección y productividad en cada paso que das"
        section="shield"
      />
      <Role
        title="Acceso inteligente y personalizado"
        header="Ejemplos de roles y permisos"
        data={items}
      />
      <OtherSections section="general"/>
      <Stories
        title="Historias como la tuya"
        description="Miles de profesionales como vos eligen y seguen eligiendo a Bilog, conoce sus experiencias."
      />
      <div className="lg:hidden">
        <Testimonial />
      </div>
    </Layout>
  );
};

export default ShieldView;
