import React from 'react';
import { Button, Copy, Icons } from '#components/atoms';
import classNames from 'classnames';
import { MdArrowForwardIos } from 'react-icons/md';

function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

export const DATA_BANNERS: {
  [key: string]: {
    title: string;
    description: React.ReactNode;
    img?: string;
    buttons: React.ReactNode;
    classname?: string;
  };
} = {
  about: {
    title: 'Conecta con nosotros',
    description: (
      <Copy className="text-center !text-base lg:text-start lg:text-xl lg:mr-28 !text-slate-900 lg:!text-slate-600">
        Se parte de la familia Bilog, registrate en la aplicación o consulta con
        ventas para conocer el mejor plan para vos.
      </Copy>
    ),
    img: 'banner-about',
    buttons: (
      <div className="w-full lg:flex lg:gap-x-4 lg:mt-6">
        <Button
          asChild
          className="mt-4 w-full"
          onClick={() => window.open('https://signup.bilog.com.ar')}
        >
          Regístrate gratis
        </Button>
        <Button
          modifier="white"
          asChild
          className="mt-4 w-full"
          onClick={() => (window.location.href = '/contact')}
        >
          Contactanos
        </Button>
      </div>
    ),
  },
  faqs: {
    title: '¿Tienes otra consulta?',
    description: (
      <>
        <Copy className="!text-base my-8 lg:my-0 text-start lg:text-xl !text-slate-900 lg:!text-slate-600">
          Ponte en contacto con nuestro equipo.
        </Copy>
        <Copy className="hidden lg:block !text-base text-start lg:text-xl !text-slate-900 lg:!text-slate-600">
          Estamos para ayudarte.
        </Copy>
      </>
    ),
    img: 'banner-queries',
    buttons: (
      <Button
        modifier={isMobileDevice() ? null : 'expansion'}
        asChild
        className="mt-16 w-full"
        onClick={() => (window.location.href = '/contact')}
      >
        <div className="flex items-center justify-between group">
          <p
            className={classNames(
              'text-left text-base font-normal whitespace-nowrap group-hover:font-bold pr-1 py-2',
              isMobileDevice() ? 'text-white' : 'text-blue-500',
            )}
          >
            Contacto con soporte
          </p>
          <MdArrowForwardIos className="text-blue-500 h-4 font-regular group-hover:font-black lg:block hidden" />
        </div>
      </Button>
    ),
  },
  home: {
    title: '¿Todo listo para empezar?',
    description: (
      <>
        <Copy className="!text-base mt-10 lg:mt-0 mb-1 text-center lg:text-start lg:mr-24 lg:text-xl !text-slate-900 lg:!text-slate-600">
          Conoce nuestras tarifas y descubre cual es la versión que mejor se
          adapta a tu negocio.
        </Copy>
        <Copy className="!text-base text-center lg:text-start lg:text-xl  !text-slate-900 lg:!text-slate-600">
          Te invitamos a simular tu inversión y a conocer la cobertura de
          nuestros planes.
        </Copy>
      </>
    ),
    img: 'banner-home',
    buttons: (
      <Button
        asChild
        className="mt-4 lg:!text-blue-500 lg:!bg-white lg:shadow-md"
        onClick={() => window.open('https://payments.bilog.com.ar')}
      >
        Descubre tarifas
      </Button>
    ),
  },
  testimonials: {
    title: 'Crece y organiza tus consultas con Bilog',
    description: (
      <Copy className="text-center !text-base lg:text-start lg:text-xl lg:mr-28 !text-slate-900 lg:!text-slate-600">
        Gestiona tus pacientes de manera eficiente y asegúrate de tener tu
        agenda siempre completa.
      </Copy>
    ),
    img: 'banner-testimonials',
    buttons: (
      <div className="w-full lg:flex lg:gap-x-4 lg:mt-1">
        <Button
          asChild
          className="mt-4 w-full"
          onClick={() =>
            window.open(
              /Android/.test(navigator.userAgent)
                ? 'https://play.google.com/store/apps/details?id=com.bilog.gomobile'
                : 'https://apps.apple.com/uy/app/bilog-gesti%C3%B3n-odontol%C3%B3gica/id1554140449',
              '_blank',
            )
          }
        >
          Descarga la app
        </Button>
        <Button
          modifier="white"
          asChild
          className="mt-4 w-full"
          onClick={() => (window.location.href = '/contact')}
        >
          Contactanos
        </Button>
      </div>
    ),
  },
  auditory_not_image: {
    title: 'Líderes en Argentina',
    description: (
      <Copy className="text-center !text-base lg:!text-slate-600 max-w-[300px] lg:max-w-[400px]">
        Solución tecnológica exclusiva para el sector odontológico en Argentina.
      </Copy>
    ),
    buttons: (
      <Button
        modifier="violet"
        asChild
        className="mt-4 w-full max-w-[350px] md:max-w-[350px] lg:max-w-[350px]"
        onClick={() => window.open('https://wapp.ly/l/20820ZeQv', '_blank')}
      >
        Contactar asesor
        <Icons type="whatsapp" fill="white" className="size-5 ml-2" />
      </Button>
    ),
    classname: 'bg-violet-50 w-full my-10 py-10',
  },
  auditory: {
    title: '¿Conoces nuestro software de gestión odontológica?',
    description: (
      <Copy className="!text-base mb-8 m-1 lg:my-0 text-center md:text-start lg:text-start lg:text-xl !text-slate-900 lg:!text-slate-600">
        Incorpora practicidad en el manejo de agenda de tus profesionales.
      </Copy>
    ),
    img: 'banner-auditory',
    buttons: isMobileDevice() ? (
      <Button
        asChild
        className="w-full"
        onClick={() => (window.location.href = '/schedule')}
      >
        Más información
      </Button>
    ) : (
      <Button
        modifier={isMobileDevice() ? null : 'expansion'}
        asChild
        className="mt-6 w-full"
        onClick={() => (window.location.href = '/schedule')}
      >
        <div className="flex items-center justify-between group">
          <p
            className={classNames(
              'text-left text-base font-normal whitespace-nowrap group-hover:font-bold pr-1 py-2',
              isMobileDevice() ? 'text-white' : 'text-blue-500',
            )}
          >
            Más información
          </p>
          <MdArrowForwardIos className="text-blue-500 h-4 font-regular group-hover:font-black lg:block hidden" />
        </div>
      </Button>
    ),
  },
  administration: {
    title: '¡No esperes más!',
    description: (
      <Copy className="!text-base mb-8 m-1 lg:my-0 text-center md:text-start lg:text-start lg:!text-xl !text-slate-900 lg:!text-slate-600">
        Simplifica procesos, ahorra tiempo y dedícale más atención a lo que
        realmente importa: tus pacientes.
      </Copy>
    ),
    img: 'banner-administration',
    buttons: (
      <Button
        asChild
        className="mt-4 lg:!text-blue-500 lg:!bg-transparent shadow-md lg:!shadow-none"
        onClick={() => window.open('https://wapp.ly/l/20820ZeQv', '_blank')}
      >
        Contactar asesor
        <Icons
          type="whatsapp"
          fill={isMobileDevice() ? 'white' : "#3B82F6"}
          className="size-5 ml-2"
        />
      </Button>
    ),
  },
};
