import React from 'react';
import {
  FaWhatsapp,
  FaAndroid,
  FaFacebookF,
  FaInstagram,
  FaApple,
  FaArrowsAltH,
  FaArrowRight,
  FaCog,
  FaChevronUp,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
  FaCheck,
  FaEnvelopeOpen,
  FaHome,
  FaInfoCircle,
  FaTimes,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaArrowUp,
  FaBars,
  FaCheckCircle,
  FaLinkedinIn,
} from 'react-icons/fa';
import {
  HiOutlineUsers,
  HiOutlineDocumentCheck,
  HiOutlineCurrencyDollar,
  HiOutlineShieldCheck,
  HiOutlineChartBar,
  HiOutlineCalendar,
  HiOutlineHeart,
  HiOutlineChatBubbleLeftRight,
  HiOutlineSquares2X2,
  HiOutlineBuildingOffice,
  HiOutlineDevicePhoneMobile,
  HiOutlineGlobeAmericas,
  HiOutlineComputerDesktop,
  HiOutlineArrowRight,
  HiOutlineCog,
  HiOutlineCheckBadge,
  HiOutlineCalendarDays,
  HiOutlineDocumentText,
} from 'react-icons/hi2';
import { PiTooth } from 'react-icons/pi';
import { LuAward, LuHeartHandshake, LuMonitorDot } from 'react-icons/lu';
import { IconsT } from '#interfaces/components/index';

const icons: { [key: string]: React.ComponentType<any> } = {
  apple: FaApple,
  android: FaAndroid,
  alert: FaExclamationCircle,
  arrowBack: FaChevronLeft,
  arrowDown: FaChevronDown,
  arrowRight: FaChevronRight,
  arrowTop: FaChevronUp,
  arrowRightIcon: FaArrowRight,
  arward: LuAward,
  close: FaTimes,
  check: FaCheck,
  config: FaCog,
  config_outline: HiOutlineCog,
  emailList: FaEnvelopeOpen,
  facebook: FaFacebookF,
  help: FaInfoCircle,
  home: FaHome,
  heartHandshake: LuHeartHandshake,
  instagram: FaInstagram,
  logout: FaArrowRight,
  refresh: FaArrowsAltH,
  whatsapp: FaWhatsapp,
  warning: FaExclamationTriangle,
  trendingUp: FaArrowUp,
  menu: FaBars,
  monitorDot: LuMonitorDot,
  checkCircle: FaCheckCircle,
  linkedin: FaLinkedinIn,
  calendar: HiOutlineCalendar,
  calendarDays: HiOutlineCalendarDays,
  user: HiOutlineUsers,
  statistics: HiOutlineChartBar,
  document: HiOutlineDocumentCheck,
  documentText: HiOutlineDocumentText,
  currency: HiOutlineCurrencyDollar,
  shield: HiOutlineShieldCheck,
  heart: HiOutlineHeart,
  chat: HiOutlineChatBubbleLeftRight,
  tooth: PiTooth,
  squares: HiOutlineSquares2X2,
  offices: HiOutlineBuildingOffice,
  mobile: HiOutlineDevicePhoneMobile,
  desktop: HiOutlineComputerDesktop,
  world: HiOutlineGlobeAmericas,
  outlineArrowRight: HiOutlineArrowRight,
  check_outline: HiOutlineCheckBadge,
};

export const Icons: React.FC<IconsT> = ({
  type,
  width,
  height,
  className,
  onClick,
  fill = 'dark',
}) => {
  const Icon = icons[type];

  return (
    <Icon
      width={width}
      height={height}
      className={className}
      fill={fill}
      onClick={onClick}
    />
  );
};
