import React from 'react';
import {
  Badge,
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Icons,
  Image,
  Title,
} from '#components/atoms';
import { data } from '#mocks/schedule';
import { useDeviceDetector } from '../../hooks';

export const TypesOf = ({
  section,
  header,
  title,
  description,
}: {
  section: string;
  header?: string;
  title: string;
  description?: string;
}) => {
  const { MobileView, DesktopView, TabletView } = useDeviceDetector();
  return (
    <Card className="text-start items-start  md:items-center flex flex-col !py-10 md:!py-20 mt-10 lg:mt-0 border-t-2 overflow-hidden lg:!border-t-4 border-t-blue-50 lg:!mx-24">
      {header && (
        <Copy className="!text-blue-400 mb-1 !text-base !font-bold lg:!text-xl 2xl:!text-2xl">
          {header}
        </Copy>
      )}
      <Title className="!text-3xl mb-5 lg:px-44 lg:mb-2 w-full !text-start lg:!text-5xl 2xl:!text-6xl md:!text-center leading-snug lg:!leading-tight">
        {title}
      </Title>
      {description && (
        <Copy className="!text-xl !text-start md:!text-center lg:block mb-10 2xl:!text-2xl">
          {description}
        </Copy>
      )}
      <MobileView>
        <Carousel>
          <CarouselContent className="mb-10">
            {data[section].map((item, index) => (
              <CarouselItem
                key={index}
                className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
              >
                <div className="py-3 max-w-[394px]">
                  <Image
                    src={require(`#assets/products/${item.img}`)}
                    width={400}
                    height={300}
                    alt="schedule-image"
                    className="mb-2.5"
                  />
                  {section.includes('administration') &&
                    item?.platforms?.map((platform: any) => (
                      <Badge
                        key={index}
                        className={`p-5 !my-4 !py-2 !text-sm ${platform.classname}
                  !rounded-full w-fit`}
                      >
                        <p>{platform.title}</p>
                      </Badge>
                    ))}
                  <Title className="!text-base !font-bold">{item.title}</Title>
                  <Copy className="!font-regular mb-2.5">
                    {item.description}
                  </Copy>
                  {section.includes('schedule') &&
                    item.platforms.map((platform: any) => (
                      <Badge
                        key={index}
                        className={`p-5 !py-2 ${platform.classname} mr-2.5
                !rounded-full w-fit`}
                      >
                        <Icons
                          type={platform?.icon}
                          fill={platform?.fill}
                          className="mr-2 size-4"
                        />
                        <p>{platform.title}</p>
                      </Badge>
                    ))}
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </MobileView>
      <TabletView>
        <Carousel>
          <CarouselContent className="mb-10">
            {data[section].map((item, index) => (
              <CarouselItem key={index} className="!basis-1/2 h-96">
                <div className="p-2 min-h-[341px]">
                  <Image
                    src={require(`#assets/products/${item.img}`)}
                    width={400}
                    height={300}
                    alt="schedule-image"
                    className="mb-2.5"
                  />
                  {section.includes('administration') &&
                    item.platforms.map((platform: any) => (
                      <Badge
                        key={index}
                        className={`p-5 !my-4 !py-2 !text-sm ${platform.classname}
                  !rounded-full w-fit`}
                      >
                        <p>{platform.title}</p>
                      </Badge>
                    ))}
                  <Title className="!text-base !font-bold">{item.title}</Title>
                  <Copy className="!font-regular mb-2.5">
                    {item.description}
                  </Copy>
                  {section.includes('schedule') &&
                    item.platforms.map((platform: any) => (
                      <Badge
                        key={index}
                        className={`p-5 !py-2 ${platform.classname} mr-2.5
                !rounded-full w-fit`}
                      >
                        <Icons
                          type={platform?.icon}
                          fill={platform?.fill}
                          className="mr-2 size-4"
                        />
                        <p>{platform.title}</p>
                      </Badge>
                    ))}
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </TabletView>
      <DesktopView>
        <div className="flex m-5">
          {data[section].map((item, index) => (
            <div className="px-3 pt-8 mx-3 w-[345px]">
              <Image
                src={require(`#assets/products/${item.img}`)}
                width={400}
                height={300}
                alt="schedule-image"
                className="mb-2.5"
              />
              {section.includes('administration') &&
                item.platforms.map((platform: any) => (
                  <Badge
                    key={index}
                    className={`p-5 !my-4 !py-2 !text-sm ${platform.classname}
                  !rounded-full w-fit`}
                  >
                    <p>{platform.title}</p>
                  </Badge>
                ))}
              <Title className="!text-base !font-bold lg:!text-xl">
                {item.title}
              </Title>
              <Copy className="!font-regular mb-2.5 lg:text-base">
                {item.description}
              </Copy>
              {section.includes('schedule') &&
                item.platforms.map((platform: any) => (
                  <Badge
                    key={index}
                    className={`p-5 !py-2 ${platform.classname} mr-2.5
                !rounded-full w-fit`}
                  >
                    <Icons
                      type={platform?.icon}
                      fill={platform?.fill}
                      className="mr-2 size-4"
                    />
                    <p>{platform.title}</p>
                  </Badge>
                ))}
            </div>
          ))}
        </div>
      </DesktopView>
    </Card>
  );
};
